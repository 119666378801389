.contact_form {
  opacity: 0;
  position: absolute;
  bottom: 110px;
  right: -400px;
  width: 350px;
  background: #111325;
  padding: 40px 25px;
  border-radius: 10px;
  box-shadow: 0 0 2px #fff;
  z-index: 2;
  transition: all 1s ease-in;
}
