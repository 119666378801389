.blogs-container {
  & h1 {
    margin-top: 80px;
    margin-bottom: 16px;
  }

  & .des {
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
  }

  .blog-wrap {
    display: flex;
    margin: 45px 0 10px;
    & a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        text-decoration-color: #00A7FF;
      }
    }
    & h4 {
      margin: 0 0 10px;
    }
    & img {
        display: block;
        margin-right: 34px;
        border-radius: 8px;
        width: 495px;
        min-width: 495px;
        height: 300px;
        object-fit: cover;
    }
    & .blog-desc {
      max-height: 96px;
      line-height: 32px;
      overflow-y: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical
    }
    & button {
        cursor: pointer;
        width: 140px;
        height: 44px;
        font-size: 20px;
        color: #0062FF;
        text-transform: uppercase;
        box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
        border: none;
        border-radius: 8px;
    }
  }
}

@media (max-width: 640px) {
  .blogs-container {
    .blog-wrap {
      display: block;
      & img {
        width: 96%;
        height: auto;
        margin: auto;
      }
    }
  }
}
