.blog-container {
    & h1 {
        margin-top: 80px;
        max-width: 1024px;
    }

    & .des {
        background-color: #fff;
        border-radius: 8px;
        padding: 16px;
    }
    & img {
        display: block;
        width: 100%;
        margin: 40px 0;
    }
    & .padding-tighten {
     padding-left: 16px;
    }
    & .text-center {
        text-align: center;
    }
}