.business_header {
  height: 480px;
  background: url('./images/service_header.jpg') 100% no-repeat;
  background-size: 100%;
}
.business_header .button {
  font-size: 32px;
  padding: 20px 50px;
  height: auto;
}
.section_services {
  margin: auto;
  max-width: 1440px;
  padding: 100px 0;
}
.section_services h1 {
  text-align: center;
  margin-bottom: 60px;
}

.section_service {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.section_item {
  display: flex;
  align-items: center;
  width: 32%;
  margin-bottom: 80px;
}
.section_item h5 {
  margin: 4px 0;
}
.section_item img {
  margin-right: 10px;
  width: 100px;
}
.section_item p {
  line-height: 20px;
  margin: 0;
}

.section_item_img {
  width: 32%;
  max-width: 360px;
}
.section_item_img img {
  margin-top: -40px;
}
.section_why {
  background-color: #fff;
}
.section_reasons {
  margin: auto;
  max-width: 1440px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 70px 0;
}

.section_reasons > img {
  width: 654px;
}
.section_content {
  width: 601px;
}

.section_content h5 {
  color: #000;
  margin: 20px 0 4px;
}
.section_content p {
  color: #979494;
}

.email_us {
  position: fixed;
  bottom: 80px;
  right: 10px;
  cursor: pointer;
  z-index: 10;
}

.email_us img {
  width: 100px;
  border: 1px solid #fff;
  border-radius: 50%;
}

@media (max-width: 640px) {
  .business_header {
    min-height: 500px;
    background-size: auto 100%;
    background-position: 65%;
  }
  .business_header img {
    height: 500px;
  }
  .business_header h2 {
    font-size: 18px;
    line-height: 24px;
  }

  .business_header .button {
    padding: 10px 20px;
    font-size: 18px;
    height: auto;
  }

  .section_services {
    padding: 20px 0;
  }
  .section_item {
    width: 90%;
    padding-left: 5%;
  }
  .section_item_img {
    display: none;
  }

  .section_reasons {
    display: block;
  }
  .section_content {
    width: auto;
    padding: 0 5%;
  }

  .email_us img {
    width: 60px;
  }
}

