.marketing-list {
  padding-bottom: $section-header--padding__desktop;
  & .tiles-item-inner {
    background-color: get-color(light, 1);
    border-radius: $img-radius;
  }
  .marketing-item-header {
    margin-top: -$tiles-item--inner-padding-v;
    margin-left: -$pricing-item--inner-padding-h;
    margin-right: -$pricing-item--inner-padding-h;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: $pricing-slider--max-w;
    }
    &::after {
      height: 0;
    }
  }

  .marketing-item-features-title {
    color: get-color(dark, 2);
  }

  .tiles-wrap {
    justify-content: flex-start;
  }

  .tiles-item {
    flex-basis: $pricing-item--width;
    max-width: $pricing-item--width;
    padding: $pricing-item--inner-padding-v;
  }
}

.marketing-detail {
  .detail-buy {
    width: 200px;
    & button {
      background-color: get-color(alert, error);
      color: get-color(light, 1);
      &.button.is-loading::after {
        border-color: get-color(light, 1);
      }
    }
  }
  .detail-info {
    padding-bottom: $section-header--padding__desktop;
    padding-top: $pricing-item--inner-padding-v;
  }
  .go-buy {
    padding-bottom: $section-header--padding__desktop;
    cursor: pointer;
  }

  .radio-item {
    margin-right: $pricing-item--inner-padding-v;
  }

  .detail-item {
    padding: 8px 0;
  }
}
