.dashboard {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  & h1 {
    display: block;
    text-align: center;
    margin: 0;
    width: 100%;
    height: 80px;
    line-height: 70px;
    font-size: 32px;
    background: url('../../../../views/Dashboard/bg-header.png') no-repeat 0 0;
    background-size: 100% 100%;
  }
  & .content {
    position: relative;
    display: flex;
    width: 100%;
    min-height: calc(100vh - 80px);
    height: 100%;
    background: url('../../../../views/Dashboard/bg.png') no-repeat 0 0;
    background-size: 100% 100%;
    padding-top: 50px;
    & .left {
      width: 24%;
    }
    & .center {
      position: relative;
      width: 52%;
      padding: 0 2%;
    }
  }
  .sec-bottom {
    position: absolute;
    left: 0;
    bottom: 86px;
    width: 100%;
    height: 300px;
  }
  & .small-sec {
    position: relative;
    display: block;
    width: 90%;
    min-height: 180px;
    margin: 0 auto 30px;
    padding: 10px;
    background: url('../../../../views/Dashboard//bg-section.png') no-repeat 100% 100%;
    background-position: 0 0;
    background-size: 100% 100%;
    .detail {
      border-radius: 0 0 4px 4px;
    }
    .title {
      padding-left: 20px;
      color: get-color(light, 2);
    }
    .price {
      position: absolute;
      top: 35px;
      left: 20px;
      color: get-color(light, 3);
    }
    .list-text {
      margin-top: 8px;
      li {
        font-size: 14px;
        margin: 0;
        padding-left: 4px;
        list-style: none;
        position: relative;
        cursor: pointer;
        &::before {
          content: ' ';
          position: absolute;
          left: -6px;
          top: 11px;
          width: 0;
          height: 0;
          border: 4px solid transparent;
          border-left: 4px solid get-color(light, 2);
        }
        &:hover {
          color: get-color(light, 1);
        }
        &:hover::before {
          border-left-color: get-color(light, 1);
        }
      }
      p {
        margin: 0;
      }
    }
  }
}

.bubbles {
  position: relative;
  height: 50vh;
  .bubble {
    position: absolute;
    width: 0;
    height: 0;
    left: 0;
    bottom: 0;
    transition: height 0.1s ease;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
    .text {
      position: absolute;
      display: block;
      left: 0;
      top: 10%;
      width: 100%;
      height: 100%;
      margin: 0;
      display: flex;
      justify-content: center;
      font-size: 12px;
      white-space: nowrap;
    }
    .name {
      position: absolute;
      white-space: nowrap;
      font-size: 12px;
      margin: 0;
      width: 100%;
      overflow: hidden;
      text-align: center;
      bottom: -4px;
    }
  }
}

.legend {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0;
  margin-left: -50px;
  font-size: 14px;
  padding: 0;
  max-width: 80px;
  & li {
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    height: 20px;
    white-space: nowrap;
    span {
      display: inline-block;
      width: 14px;
      min-width: 14px;
      height: 14px;
      margin-right: 6px;
    }
  }
}

.circular {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  transform: rotate(0deg) translateY(0px);
  & path {
    fill: none;
  }
  & svg {
    display: block;
    overflow: visible;
  }
  & textPath {
    stroke: get-color(light, 1);
    stroke-width: 1px;
    letter-spacing: 2px;
    fill: get-color(light, 1);
  }
}

.circle-box {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 20px;
  border-radius: 50%;
  padding: 2px;
  display: flex;
  align-items: center;
  & p {
    width: 100%;
    margin: 0;
    font-size: 12px;
    color: red;
    text-align: center;
    line-height: 8px;
    white-space: nowrap;
  }
}
