.pricing {
  background-color: color(heading);
  .section-inner {
    padding-top: $pricing--padding-t__mobile;
    padding-bottom: $pricing--padding-b__mobile;
  }

  .section-header {
    padding-bottom: $pricing-header--padding__mobile;
  }

  .tiles-wrap {
    z-index: 0;
    justify-content: space-around;
    @if ($pricing-items--padding__mobile != null) {
      margin-right: -($pricing-items--padding__mobile / 2);
      margin-left: -($pricing-items--padding__mobile / 2);
      margin-top: -($pricing-items--padding__mobile / 2);

      &:last-of-type {
        margin-bottom: -($pricing-items--padding__mobile / 2);
      }

      &:not(:last-of-type) {
        margin-bottom: ($pricing-items--padding__mobile / 2);
      }
    }

    &.push-left {
      &::after {
        flex-basis: $pricing-item--width;
        max-width: $pricing-item--width;
        @if ($pricing-items--padding__mobile != null) {
          padding-left: $pricing-items--padding__mobile / 2;
          padding-right: $pricing-items--padding__mobile / 2;
        }
      }
    }
  }

  .carousel-bullets {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .tiles-item {
    flex-basis: $pricing-item--width;
    max-width: $pricing-item--width;
    @if ($pricing-items--padding__mobile != null) {
      padding: $pricing-items--padding__mobile / 2;
    }
  }

  .tiles-item-inner {
    padding-top: $pricing-item--inner-padding-v;
    padding-bottom: $pricing-item--inner-padding-v;
    padding-left: $pricing-item--inner-padding-h;
    padding-right: $pricing-item--inner-padding-h;
  }
}

.pricing-item-content {
  flex-grow: 1;
  width: 100%;
}

.pricing-item-header {
  margin-top: -$pricing-item--inner-padding-v;
  margin-left: -$pricing-item--inner-padding-h;
  margin-right: -$pricing-item--inner-padding-h;
  img {
    object-fit: cover;
    width: 100%;
    height: $pricing-slider--max-w;
  }
  &::after {
    height: 0;
  }
}

.pricing-item-description {
  max-height: 62px;
  overflow: hidden;
  position: relative;
  &::before {
    content: '...';
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    width: 1.5em;
    padding-left: 4px;
    box-sizing: border-box;
    background-color: transparent;
  }
}

ul.pricing-item-features-list {
  li {
    display: flex;
    align-items: center;
    margin-bottom: $spacing--shared-v / 2;
  }
}

.pricing-switcher,
.pricing-slider {
  margin-bottom: 48px;
}

.pricing-slider {
  max-width: $pricing-slider--max-w;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  input {
    width: 100%;
  }

  .pricing-slider-value {
    position: absolute;
    @include font-size(xxs);
    @include font-weight(label);
    color: color(low-contrast);
    margin-top: 8px;
    --thumb-size: #{$range-thumb-size};
  }
}

.invert-color {
  .pricing-slider-value {
    color: color(low-contrast-inverse);
  }
}

@include media('>medium') {
  .pricing {
    .section-inner {
      padding-top: $pricing--padding-t__desktop;
      padding-bottom: $pricing--padding-b__desktop;
    }

    .section-header {
      padding-bottom: $pricing-header--padding__desktop;
    }

    @if ($pricing-items--padding__desktop != null) {
      .tiles-wrap {
        margin-right: -($pricing-items--padding__desktop / 2);
        margin-left: -($pricing-items--padding__desktop / 2);
        margin-top: -($pricing-items--padding__desktop / 2);

        &:last-of-type {
          margin-bottom: -($pricing-items--padding__desktop / 2);
        }

        &:not(:last-of-type) {
          margin-bottom: ($pricing-items--padding__desktop / 2);
        }

        &.push-left {
          &::after {
            padding-left: $pricing-items--padding__desktop / 2;
            padding-right: $pricing-items--padding__desktop / 2;
          }
        }
      }

      .tiles-item {
        padding: $pricing-items--padding__desktop / 2;
      }
    }
  }

  .pricing-switcher,
  .pricing-slider {
    margin-top: -32px;
    margin-bottom: 64px;
  }
}

.marketing-title {
  & h2 {
    color: color(heading-inverse);
  }
}

.marketing-more {
  width: $pricing-slider--max-w;
  margin: auto;
  padding: $section-header--padding__desktop;
}
